<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import DeadlineChart from "@/components/PropertyDetailsComponents/PropertyDetailsDeadline/DeadlineChart.vue";
import DeadlineCameras from "@/components/PropertyDetailsComponents/PropertyDetailsDeadline/DeadlineCameras.vue";
import DeadlineChartTable from "@/components/PropertyDetailsComponents/PropertyDetailsDeadline/DeadlineChartTable.vue";
import DeadlineTable from "@/components/PropertyDetailsComponents/PropertyDetailsDeadline/DeadlineTable.vue";
import DeadlineFloorsTable from "@/components/PropertyDetailsComponents/PropertyDetailsDeadline/DeadlineFloorsTable.vue";

import QualityCameras from "@/components/PropertyDetailsComponents/PropertyDetailsQuality/QualityCameras.vue";
import QualityChart from "@/components/PropertyDetailsComponents/PropertyDetailsQuality/QualityChart.vue";
import QualityTable from "@/components/PropertyDetailsComponents/PropertyDetailsQuality/QualityTable.vue";

import ActionsMain from "@/components/PropertyDetailsComponents/PropertyDetailsActions/ActionsMain.vue";

import ProductionCameras from "@/components/PropertyDetailsComponents/PropertyDetailsProduction/ProductionCameras.vue";
import ProductionChart from "@/components/PropertyDetailsComponents/PropertyDetailsProduction/ProductionChart.vue";
import ProductionTreeview from "@/components/PropertyDetailsComponents/PropertyDetailsProduction/ProductionTreeview.vue";
import ProductivityTable from "@/components/PropertyDetailsComponents/PropertyDetailsProduction/ProductivityTable.vue";

import TopTenTable from "@/components/PropertyDetailsComponents/PropertyDetailsTopTen/TopTenTable.vue";

import SpecialReportTable from "@/components/PropertyDetailsComponents/PropertyDetailsSpecialReport/SpecialReportTable.vue";
import PropertyDetailsSideCards from "@/components/PropertyDetailsSideCards.vue";

const {
  ValueRequest_Int32,
} = require("../proto-out/IMultiConstructionService-v4_pb");
const {
  MultiConstructionServiceClient,
} = require("../proto-out/IMultiConstructionService-v4_grpc_web_pb");

const route = useRoute();
const construction = ref({});
const isLoading = ref(true);

const store = useStore();
let clickedButton = computed(
  () => store.getters["clickedButton/getClickedButton"],
);

let currentIndex = computed(
  () => store.getters["currentIndex/getCurrentIndex"],
);

let computedIndexLength = computed(() => {
  return componentsList[clickedButton.value].length - 1 || 0;
});

const showCards = ref(true);
const showCams = ref(true);

const toggleCards = () => {
  showCards.value = !showCards.value;
};
const toggleCams = () => {
  showCams.value = !showCams.value;
};

const hideSideCards = computed(() => {
  return clickedButton.value === "КАЧЕСТВО" && currentIndex.value === 3
    ? false
    : clickedButton.value === "ТОП10"
      ? false
      : true;
});
const blackBackground = computed(() => {
  return (clickedButton.value === "КАЧЕСТВО" && currentIndex.value === 1) ||
    (clickedButton.value === "СРОКИ" && currentIndex.value === 1) ||
    (clickedButton.value === "СРОКИ" && currentIndex.value === 2) ||
    (clickedButton.value === "ПРОИЗВОДСТВО" && currentIndex.value === 1)
    ? true
    : false;
});
const componentsList = {
  СРОКИ: [
    DeadlineCameras,
    DeadlineChart,
    DeadlineChartTable,
    DeadlineFloorsTable,
    DeadlineTable,
  ],
  КАЧЕСТВО: [QualityCameras, QualityChart, DeadlineFloorsTable, QualityTable],
  ПРОИЗВОДСТВО: [
    ProductionCameras,
    ProductionChart,
    ProductionTreeview,
    ProductivityTable,
  ],
  ТОП10: [TopTenTable],
  СПЕЦОТЧЕТ: [SpecialReportTable],
  ДЕЙСТВИЯ: [ActionsMain],
};

const showNextComponent = () => {
  store.commit(
    "currentIndex/setCurrentIndex",
    (currentIndex.value + 1) % componentsList[clickedButton.value].length,
  );
  store.dispatch("appData/setCurrentSubLocationId", 0);
};

const showPreviousComponent = () => {
  store.commit(
    "currentIndex/setCurrentIndex",
    (currentIndex.value - 1 + componentsList[clickedButton.value].length) %
      componentsList[clickedButton.value].length,
  );
};

const arrowIconSrc = computed(() => {
  return showCards.value
    ? new URL("@/assets/icons/arrow_left1.svg", import.meta.url).href
    : new URL("@/assets/icons/arrow_right1.svg", import.meta.url).href;
});

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const fetchDataFromGrpc = async () => {
  const id = route.params.id;
  const client = new MultiConstructionServiceClient(serverUrl.value);
  const metadata = { Authorization: `Bearer ${authToken.value}` };
  const actualInstance = new ValueRequest_Int32();
  actualInstance.setValue(id);

  return new Promise((resolve, reject) => {
    client.getConstruction(actualInstance, metadata, (err, response) => {
      if (err) {
        console.error("gRPC Error:", err.message);
        reject(new Error("Failed to fetch data from gRPC"));
      } else {
        const responseObject = response.toObject();
        construction.value = responseObject;
        resolve(responseObject);
        isLoading.value = false;
      }
    });
  });
};
const fetchData = async () => {
  try {
    await fetchDataFromGrpc();
  } catch (err) {
    error.value = "Failed to load data: " + err.message;
    console.error(error.value);
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  await fetchData();
});
</script>

<template>
  <v-responsive class="align-center mx-auto">
    <v-overlay :model-value="isLoading" class="align-center justify-center">
      <v-progress-circular
        style="margin: 10px"
        size="120"
        width="7"
        indeterminate
        color="primary"
      >
      </v-progress-circular>
    </v-overlay>
    <v-fade-transition>
      <v-row v-if="!isLoading" fluid dense>
        <!-- Dash Cards -->
        <v-col
          :class="showCards ? 'col-3-5' : 'col-0-5'"
          v-show="hideSideCards"
        >
          <v-row dense>
            <v-col v-show="showCards" class="col-11">
              <PropertyDetailsSideCards
                v-if="!isLoading"
                :construction="construction"
              />
            </v-col>
            <v-col class="col-1">
              <v-btn
                @click="toggleCards"
                rounded="sm"
                class="thin-btn"
                height="83vh"
                color="#2E414C"
                :disabled="!showCams"
              >
                <img :src="arrowIconSrc" />
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          :class="
            !hideSideCards ? 'col-11-5' : showCards ? 'col-8-5' : 'col-11-5'
          "
          :style="{ background: blackBackground ? 'black' : 'none' }"
          class="mt-1"
        >
          <div
            class="d-flex align-center justify-center"
            style="height: 100%; position: absolute"
            :style="{
              width: !hideSideCards ? '100%' : showCards ? '70%' : '100%',
            }"
          ></div>
          <v-card class="main pa-6 pt-10 pb-5 ma-0 ml-1">
            <!-- Left Arrow -->
            <v-img
              v-if="currentIndex > 0"
              src="@/assets/icons/arrow_left.svg"
              contain
              width="1.5vw"
              height="1.5vw"
              class="arrow-left"
              @click="showPreviousComponent"
            />

            <!-- Right Arrow -->
            <v-img
              v-if="currentIndex < computedIndexLength"
              src="@/assets/icons/arrow_right.svg"
              contain
              width="1.5vw"
              height="1.5vw"
              class="arrow"
              @click="showNextComponent"
            />

            <!-- Main Content -->
            <component
              :is="componentsList[clickedButton][currentIndex]"
              :showCards="showCards"
              :showCams="showCams"
              @toggle-cards="toggleCards"
              @toggle-cams="toggleCams"
              :construction="construction"
              :isLoading="isLoading"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-fade-transition>
  </v-responsive>
</template>

<style scoped>
.main {
  background-color: transparent;
  border: 1px solid #00a3ff;
  padding: 10px;
  box-shadow: inset 0px 0px 15px 5px #00466e;
  border-radius: 8px;
  height: 83vh;
}
.arrow {
  cursor: pointer;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}
.arrow-left {
  cursor: pointer;
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}
.thin-btn {
  min-width: 16px;
  max-width: 16px;
  margin-top: 5px;
  padding: 8px;
  outline: none;
}
.col-3-5 {
  flex-basis: 32%;
  max-width: 32%;
}

.col-8-5 {
  flex-basis: 68%;
  max-width: 68%;
}
.col-1 {
  flex-basis: 4%;
  max-width: 4%;
}
.col-11 {
  flex-basis: 96%;
  max-width: 96%;
}
.col-0-5 {
  flex-basis: 1%;
  max-width: 1%;
}
.col-11-5 {
  flex-basis: 99%;
  max-width: 99%;
}
@media (max-height: 710px) {
  .main {
    height: 77.9vh !important;
  }
  .thin-btn {
    height: 78vh !important;
  }
}
@media (min-height: 710px) and (max-height: 760px) {
  .main {
    height: 79vh !important;
  }
  .thin-btn {
    height: 79vh !important;
  }
}
@media (min-height: 760px) and (max-height: 810px) {
  .main {
    height: 81vh !important;
  }
  .thin-btn {
    height: 81vh !important;
  }
}
@media (min-height: 810px) and (max-height: 900px) {
  .main {
    height: 81vh !important;
  }
  .thin-btn {
    height: 81vh !important;
  }
}
</style>
