<script setup>
defineProps({
  icon: String,
  img: String,
  title: String,
  subtitle: String,
  data: Object,
  realData: Object,
  isLoading: Boolean,
});

const icons = [
  {
    src: new URL("@/assets/icons/mat.png", import.meta.url).href,
  },
  {
    src: new URL("@/assets/icons/tara.png", import.meta.url).href,
  },
  {
    src: new URL("@/assets/icons/ttk.png", import.meta.url).href,
  },
  {
    src: new URL("@/assets/bg/product.png", import.meta.url).href,
  },
];

const getArrowImage = (number) => {
  return number < 0
    ? new URL("@/assets/icons/red_arrow.svg", import.meta.url).href
    : new URL("@/assets/icons/green_arrow.svg", import.meta.url).href;
};
</script>

<template>
  <v-card color="transparent" class="card pr-5" rounded="lg" width="100%">
    <v-row align="center" position="fixed" class="title-wrapper pb-3">
      <!-- Icon -->
      <v-col cols="1 mr-5" class="pa-0 ma-0">
        <img
          :src="icon"
          alt="Icon"
          class="responsive-icon ma-3"
          height="40"
          width="40"
          contain
        />
      </v-col>

      <!-- Title -->
      <v-col cols="9" class="pl-0">
        <v-row>
          <v-card-text class="responsive-text">
            {{ title }}
            <span class="gray-text">{{ subtitle }}</span>
          </v-card-text>
        </v-row>
      </v-col>
    </v-row>

    <!-- Main -->
    <v-card
      class="overflow-y-auto scroll pr-5 responsive-main-card"
      color="transparent"
      height="20.9vh"
    >
      <v-progress-linear
        color="blue"
        indeterminate
        v-if="isLoading"
      ></v-progress-linear>
      <v-row
        v-else
        v-for="(item, index) in data"
        :key="index"
        align="center"
        justify="center"
        class="pa-0 ma-0 responsive-card"
      >
        <v-col class="col-1 pa-0">
          <span class="number" color="#00A3FF">{{ index + 1 }}</span>
        </v-col>
        <v-col class="col-2 pa-0">
          <v-img :src="img" width="100%" class="responsive-img">
            <v-img
              v-if="
                item.name?.includes('МАТ') ||
                item.contractorname.includes('МАТ')
              "
              :src="icons[0].src"
              height="40"
              width="40"
              contain
              class="text-center py-auto ma-auto mt-1"
            ></v-img>
            <v-img
              v-else-if="
                item.name?.includes('ТАРА') ||
                item.contractorname.includes('ТАРА')
              "
              :src="icons[1].src"
              height="40"
              width="40"
              class="text-center py-auto ma-auto mt-1"
            ></v-img>
            <v-img
              v-else-if="
                item.name?.includes('ТТК') ||
                item.contractorname.includes('ТТК')
              "
              :src="icons[2].src"
              height="40"
              width="40"
              class="text-center py-auto ma-auto mt-1"
            ></v-img>
            <v-img
              v-else-if="
                item.name?.includes('ПРОДУКТ') ||
                item.contractorname.includes('ПРОДУКТ')
              "
              :src="icons[3].src"
              height="40"
              width="40"
              class="text-center py-auto ma-auto mt-1"
            ></v-img>
          </v-img>
        </v-col>
        <v-col class="text-uppercase pa-0">
          <span class="main-card-text">{{
            item.name || item.contractorname
          }}</span>
        </v-col>
        <v-col class="col-4">
          <v-row
            v-if="
              item.name?.includes('МАТ') || item.contractorname.includes('МАТ')
            "
            align="center"
            justify="end"
          >
            <span class="mr-1 main-card-text">
              {{ realData.materialpredictiondays }}
            </span>
            <img :src="getArrowImage(realData.materialpredictiondays)" />
          </v-row>

          <v-row
            v-else-if="
              item.name?.includes('ТАРА') ||
              item.contractorname.includes('ТАРА')
            "
            align="center"
            justify="end"
          >
            <span class="mr-1 main-card-text">
              {{ realData.packagepredictiondays }}
            </span>
            <img :src="getArrowImage(realData.packagepredictiondays)" />
          </v-row>

          <v-row
            v-else-if="
              item.name?.includes('ТТК') || item.contractorname.includes('ТТК')
            "
            align="center"
            justify="end"
          >
            <span class="mr-1 main-card-text">
              {{ realData.ttkpredictiondays }}
            </span>
            <img :src="getArrowImage(realData.ttkpredictiondays)" />
          </v-row>

          <v-row
            v-else-if="
              item.name?.includes('ПРОДУКТ') ||
              item.contractorname.includes('ПРОДУКТ')
            "
            align="center"
            justify="end"
          >
            <span class="mr-1 main-card-text">
              {{ realData.productpredictiondays }}
            </span>
            <img :src="getArrowImage(realData.productpredictiondays)" />
          </v-row>
        </v-col>

        <v-divider
          v-if="index !== data.length - 1"
          class="border-opacity-100 custom-divider"
          inset
        ></v-divider>
      </v-row>
    </v-card>
  </v-card>
</template>

<style scoped>
.card {
  border: 1px solid #00a3ff;
  padding: 10px;
  box-shadow: inset 0px 0px 15px 5px #00466e;
}
.text-title {
  font-size: 18px;
}
.number {
  background-color: #00466e;
  height: 2.5vw;
  font-size: 0.7em;
  color: #00a3ff;
  background-color: #00466e;
  padding: 0.85vw 0.2vw;
}
.custom-divider {
  margin-left: 6.5vw;
}
.responsive-card {
  padding: 0.5vw 0 !important;
  padding-left: 0.5vw !important;
}
.responsive-icon {
  max-width: 100%;
  height: 40px;
}
.responsive-img {
  height: 2.5vw;
}
.responsive-text {
  font-size: 1vw;
}
.gray-text {
  color: gray;
  font-size: 1vw;
}
.responsive-main-card {
  height: 100%;
}
.main-card-text {
  font-size: 0.85vw;
}
:deep(.v-card--variant-elevated) {
  box-shadow: none;
}
@media (min-width: 1920px) {
  .main-card-text {
    font-size: 0.7vw;
  }
  .responsive-img {
    height: 2vw;
  }
}

.col-1 {
  flex-basis: 2%;
  max-width: 2%;
}
.col-2 {
  flex-basis: 25%;
  max-width: 25%;
}
.col-3 {
  flex-basis: 30%;
  max-width: 30%;
}
.col-4 {
  flex-basis: 12%;
  max-width: 12%;
}
.scroll::-webkit-scrollbar-track {
  margin: 15px;
}
@media (max-width: 1485px) {
  .col-4 {
    flex-basis: 12%;
    max-width: 16%;
  }
}
@media (max-height: 710px) {
  .title-wrapper {
    padding-bottom: 0px !important;
  }
  .responsive-main-card {
    height: 19vh !important;
  }
}
@media (min-height: 710px) and (max-height: 760px) {
  .responsive-main-card {
    height: 17.5vh !important;
  }
}
@media (min-height: 760px) and (max-height: 810px) {
  .responsive-main-card {
    height: 19.5vh !important;
  }
}
@media (min-height: 810px) and (max-height: 860px) {
  .responsive-main-card {
    height: 20.5vh !important;
  }
}
@media (min-height: 860px) and (max-height: 900px) {
  .responsive-main-card {
    height: 20.5vh !important;
  }
}
</style>
