<script setup>
import tableHeaders from "@/utils/tableHeaders.js";
import thumbUpIcon from "@/assets/icons/thumbup.svg";
import thumbDownIcon from "@/assets/icons/thumbdown.svg";
import { ref, onMounted, computed } from "vue";
import { formatDate } from "@/utils/formatDate";
import * as XLSX from "xlsx";
import { useStore } from "vuex";

const { qualityHeaders } = tableHeaders;

const qualityData = ref([]);
const isLoading = ref(true);

const store = useStore();
const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});

let subLocationIndex = computed(
  () => store.getters["appData/getCurrentSubLocationId"],
);

const {
  ConstructionStructureRequest,
} = require("../../../proto-out/IMultiStatisticsService-v5_pb");

const {
  MultiStatisticsServiceClient,
} = require("../../../proto-out/IMultiStatisticsService-v5_grpc_web_pb");

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const showModal = ref(false);
const selectedImage = ref(null);

const openModal = (imageSrc) => {
  selectedImage.value = imageSrc;
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
  selectedImage.value = null;
};

// Sort logic
const sortData = (tabledata) => {
  return tabledata.map((item) => {
    if (item.createdatetime && item.createdatetime.seconds) {
      item.computedDate = new Date(item.createdatetime.seconds * 1000);
      item.formattedDate = formatDate(item.createdatetime.seconds);
    } else {
      item.computedDate = null;
      item.formattedDate = "N/A";
    }

    if (item.fixdatetime && item.fixdatetime.seconds) {
      item.computedFixDate = new Date(Number(item.fixdatetime.seconds) * 1000);
      item.fixDateTime = formatDate(item.fixdatetime.seconds);
    } else {
      item.computedFixDate = null;
      item.fixDateTime = "N/A";
    }

    if (item.photofilepath) {
      item.photofilepathfull = item.photofilepath
      item.photofilepath = item.photofilepath + "/S-150"
    }

    if (item.schemefilepath) {
      item.schemefilepathfull = item.schemefilepath
      item.schemefilepath = item.schemefilepath + "/S-150"
    }

    return item;
  });
};
// Fillter logic
const startDate = ref("2023-01-01");
const endDate = ref("2023-01-01");

const minEndDate = computed(() => startDate.value);
const applyDateFilter = () => {
  const start = new Date(startDate.value);
  const end = new Date(endDate.value);

  const data = qualityData.value.filter((item) => {
    // console.log(item);
    const itemDate = new Date(item.createdatetime.seconds * 1000);
    return itemDate >= start && itemDate <= end;
  });

  qualityData.value = data;
};

const fetchDataFromGrpc = async () => {
  try {
    const client = new MultiStatisticsServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };

    const request = new ConstructionStructureRequest();
    request.setConstructioninstance(props.construction.actualinstance);
    request.setStructureid(subLocationIndex.value || 0);

    // console.log(request);

    const getDetailedStructureDeviationsData = await new Promise(
      (resolve, reject) => {
        client.getDetailedStructureDeviations(
          request,
          metadata,
          (err, response) => {
            if (err) {
              console.error("gRPC Error:", err.message);
              reject(new Error("Failed to fetch data from gRPC"));
            } else {
              resolve(response.toObject());
            }
          },
        );
      },
    );
    // console.log(getDetailedStructureDeviationsData);
    qualityData.value = sortData(getDetailedStructureDeviationsData.valuesList);
  } catch (error) {
    console.error(error);
  }
  isLoading.value = false;
};
onMounted(() => {
  fetchDataFromGrpc();
});

const formatDateToReal = (seconds) => {
  const date = new Date(seconds * 1000);
  return date.toLocaleDateString();
};

const calculatePercentageExport = (lo, hi, signScale) => {
  if (hi !== 0) {
    const percentage = ((lo / hi) * 100 * signScale).toFixed(2);
    return percentage;
  }
  return "0";
};

const exportToExcel = () => {
  const headers = qualityHeaders.map((header) => header.title);
  const rows = qualityData.value.map((item) => {
    return qualityHeaders.map((header) => {
      if (header.key === "date") {
        return item.date && item.date.seconds
          ? formatDateToReal(item.date.seconds)
          : "";
      } else if (header.key === "fact") {
        return item.fact
          ? `${calculatePercentageExport(
              item.fact.lo,
              item.fact.hi,
              item.fact.signScale,
            )}%`
          : "";
      } else if (header.key === "isfixed") {
        return item.isfixed ? `ИСПРАВЛЕНО` : "НЕ ИСПРАВЛЕНО";
      } else {
        return item[header.key] ?? "";
      }
    });
  });
  const worksheetData = [headers, ...rows];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  headers.forEach((_, colIdx) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIdx });
    if (!worksheet[cellAddress]) return;
    worksheet[cellAddress].s = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { fgColor: { rgb: "D9EAD3" } },
    };
  });

  worksheet["!cols"] = headers.map(() => ({ wpx: 150 }));

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Отчет");

  const date = new Date();
  const dateString = date.toLocaleDateString("en-GB").replace(/\//g, "_");
  const fileName = `${dateString}_мултикейс_качество_отчет.xlsx`;
  XLSX.writeFile(workbook, fileName);
};

const exportServerToExcel = () => {
  // https://novgorod.grpc.ibpspb.ru/export/StructureDeviations/{constructionInstance}/{structureId}
  const url = `${serverUrl.value}export/StructureDeviations/${props.construction.actualinstance}/${subLocationIndex.value}`;
  window.open(url, "_blank");
};
</script>

<template>
  <v-row class="pa-0 ma-0 mb-5">
    <v-col class="pa-0 ma-0" lg="4" md="2"> </v-col>
    <v-col class="pa-0 ma-0" lg="6" md="8">
      <v-row class="pa-0 ma-0 mb" align="center">
        <v-card-text class="pa-0 ma-0 date capitalize-text">
          от даты
        </v-card-text>
        <input
          type="date"
          class="pa-0 ma-0 mr-5 calendar"
          v-model="startDate"
        />
        <v-card-text class="pa-0 ma-0 date capitalize-text">
          ДО даты
        </v-card-text>
        <input
          type="date"
          class="pa-0 ma-0 mr-5 calendar"
          v-model="endDate"
          :min="minEndDate"
        />
        <v-btn
          class="capitalize-text"
          rounded="md"
          density="compact"
          width="174"
          @click="applyDateFilter"
        >
          Применить
        </v-btn>
      </v-row>
    </v-col>
    <v-col class="pa-0 ma-0" lg="2" md="2" align="end">
      <v-btn
        @click="exportServerToExcel"
        class="capitalize-text"
        rounded="md"
        density="compact"
        width="174"
      >
        ЭКСПОРТ
      </v-btn>
    </v-col>
  </v-row>

  <!-- Data Table -->
  <v-card style="background-color: transparent">
    <v-data-table
      :headers="qualityHeaders"
      :items="qualityData"
      item-value="id"
      height="70vh"
      fixed-header
      hide-default-footer
      :items-per-page="-1"
      :loading="isLoading"
      loading-text="Загрузка данных..."
    >
      <template v-slot:no-data>
        <div class="text-center">
          <p>Не найдено ни одного элемента</p>
        </div>
      </template>
      <template v-slot:[`item.isfixed`]="{ item }">
        <span
          :class="{
            'status-red': !item.isfixed,
            'status-green': item.isfixed,
          }"
        >
          {{ item.isfixed ? "ИСПРАВЛЕНО" : "НЕ ИСПРАВЛЕНО" }}
        </span>
        <img
          :src="item.isfixed ? thumbUpIcon : thumbDownIcon"
          class="mt-1"
          width="33"
          height="33"
        />
      </template>
      <template v-slot:[`item.computedDate`]="{ item }">
        <span>
          {{ item.formattedDate }}
        </span>
      </template>
      <template v-slot:[`item.computedFixDate`]="{ item }">
        <span>
          {{ item.fixDateTime }}
        </span>
      </template>
      <template v-slot:[`item.photofilepath`]="{ item }">
        <img
          :src="item.photofilepath"
          width="68"
          height="54"
          @click="openModal(item.photofilepathfull)"
          style="cursor: pointer"
        />
      </template>
      <template v-slot:[`item.schemefilepath`]="{ item }">
        <img
          :src="item.schemefilepath"
          width="68"
          height="54"
          @click="openModal(item.schemefilepathfull)"
          style="cursor: pointer"
        />
      </template>
    </v-data-table>

    <v-dialog v-model="showModal" max-width="800px">
      <v-card class="position-relative">
        <v-btn icon @click="closeModal" class="popup-close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-img :src="selectedImage" class="fullscreen-image" max-width="100%" />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style scoped>
/* Fullscreen Image Popup */
.fullscreen-image {
  width: 100%;
  height: auto;
}

.popup-close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.capitalize-text {
  text-transform: uppercase;
  font-size: 13px;
}

.v-data-table {
  background-color: transparent;
}
.v-data-table :deep(table) {
  border-spacing: 0 0.5rem !important;
}
:deep(.v-data-table thead th) {
  background: linear-gradient(#006aa6, #002940) !important;
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
  text-transform: uppercase;
  padding: 10px !important;
  font-size: 12px;
}
:deep(.v-data-table thead th:first-child) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
:deep(.v-data-table thead th:last-child) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
:deep(.v-data-table tbody tr) {
  background-color: transparent;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
}
:deep(.v-data-table tbody tr):hover {
  opacity: 0.8;
}
:deep(.v-data-table ::-webkit-scrollbar-track) {
  margin: 0 !important;
  margin-top: 4.1em !important;
}
.status-red {
  color: #ff0000;
}
.status-green {
  color: #1bc200;
}
.date {
  color: #00a3ff;
  font-size: 12px;
  max-width: 5em;
}
.calendar {
  background-color: white !important;
  color: black !important;
  border-radius: 4px;
  width: 100px;
  font-size: 13px;
}

.calendar::-webkit-datetime-edit,
.calendar::-webkit-inner-spin-button,
.calendar::-webkit-clear-button {
  color: #00507d !important;
}

.calendar::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
</style>
