<template>
  <Pie
    id="my-chart-id"
    :options="{
      ...options,
      responsive: true,
      maintainAspectRatio: false,
    }"
    :data="data"
  />
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const props = defineProps({
  data: Object,
  isLoading: Boolean,
});

const data = computed(() => ({
  labels: props.data.map((item) => item.num),
  datasets: [
    {
      data: props.data.map((item) => item.percent.toFixed(2)),
      backgroundColor: [
        "rgb(240,50,0)",
        "rgb(83,236,18)",
        "rgb(255,183,0)",
        "rgb(0,168,238)",
        "rgb(207,0,142)",
      ],
    },
  ],
}));

const options = {
  responsive: true,
  maintainAspectRatio: false,
  layout: { padding: { top: 35, bottom: 35 } },
  plugins: {
    legend: { display: false },
    datalabels: {
      color: "#FAFAFA",
      formatter: (value, ctx) => {
        const colors = ["🔴", "🟢", "🟠", "🔵", "🟣"];
        const index = ctx.dataIndex;
        const dot = colors[index % colors.length];
        return `${dot} ${value} %`;
      },
      font: { size: 20 },
      clamp: true,
      anchor: "end",
      align: "end",
      offset: 10,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        title: (tooltipItems) => {
          const index = tooltipItems[0].dataIndex;
          const fullTitle = props.data[index].name;

          // Split the title into lines if it's too long (e.g., 20 characters per line)
          const maxLineLength = 30;
          const lines =
            fullTitle.match(new RegExp(`.{1,${maxLineLength}}`, "g")) || [];
          return lines;
        },
        label: () => {
          return "";
        },
      },
    },
  },
};

const gradientColors = [
  ["rgba(240,50,0,1)", "rgba(193,11,0,1)"],
  ["rgba(3,350,18,1)", "rgba(25,212,34,1)"],
  ["rgba(255,183,0,1)", "rgba(254,93,0,1)"],
  ["rgba(0,168,238,1)", "rgba(0,73,214,1)"],
  ["rgba(207,0,142,1)", "rgba(154,0,253,1)"],
];

function createGradients(chart) {
  const ctx = chart.ctx;
  const chartArea = chart.chartArea;
  if (!chartArea) return null;

  return chart.getDatasetMeta(0).data.map((segment, index) => {
    const { x, y } = segment.getCenterPoint();
    const radius = chartArea.width / 5; // Adjust radius as needed

    const gradient = ctx.createRadialGradient(x, y, 0, x, y, radius);
    gradient.addColorStop(0, gradientColors[index % gradientColors.length][0]);
    gradient.addColorStop(1, gradientColors[index % gradientColors.length][1]);

    return gradient;
  });
}

ChartJS.register({
  id: "gradientsPlugin",
  beforeDraw(chart) {
    const gradients = createGradients(chart);
    if (gradients) {
      chart.data.datasets[0].backgroundColor = gradients;
      chart.update();
    }
  },
});
const chartRef = ref(null);
const chartContainerRef = ref(null);

const resizeChart = () => {
  if (chartRef.value?.chart) {
    chartRef.value.chart.resize();
  }
};

onMounted(() => {
  const observer = new ResizeObserver(() => {
    resizeChart();
  });

  if (chartContainerRef.value) {
    observer.observe(chartContainerRef.value);
  }

  window.addEventListener("resize", resizeChart);

  onBeforeUnmount(() => {
    window.removeEventListener("resize", resizeChart);
    if (chartContainerRef.value) {
      observer.unobserve(chartContainerRef.value);
    }
  });
});
</script>
