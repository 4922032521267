<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
const iconSignals = new URL("@/assets/icons/worker.svg", import.meta.url).href;
const imgSignals = new URL("@/assets/bg/object7.png", import.meta.url).href;
const iconFiles = new URL("@/assets/icons/files.svg", import.meta.url).href;
const imgFiles = new URL("@/assets/bg/hat_yellow.png", import.meta.url).href;
const iconQuality = new URL("@/assets/icons/home1.svg", import.meta.url).href;
const imgQuality = new URL("@/assets/icons/object7.svg", import.meta.url).href;
import dummyData from "@/utils/dummyData.js";
import ProgressBarCardObject from "./data/ProgressBarCardObject.vue";
import PropertyCard from "./PropertyCard.vue";
import PropertyCardSubLocation from "./PropertyCardSubLocation.vue";
const { quality } = dummyData;

const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const clickedButton = computed(
  () => store.getters["clickedButton/getClickedButton"],
);

const data = ref([]);
const contractorsData = ref([]);
const prodCollapse = ref([]);
const isLoading = ref(true);

const generateItemImage = (multiply, itemSrc) => {
  if (itemSrc.startsWith("http")) {
    const base = 180;
    return itemSrc + `/S-${base * multiply}`;
  } else {
    return itemSrc;
  }
};

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const {
  MultiStatisticsServiceClient,
} = require("../proto-out/IMultiStatisticsService-v5_grpc_web_pb");

const {
  ValueRequest_String,
} = require("../proto-out/IMultiStatisticsService-v5_pb.js");

const fetchDataFromGrpc = async () => {
  try {
    const client = new MultiStatisticsServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };
    const request = new ValueRequest_String();
    request.setValue(props.construction.actualinstance);

    const structuresExecutionTree = await new Promise((resolve, reject) => {
      client.getStructuresExecutionTree(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });

    const contractorsDynamics = await new Promise((resolve, reject) => {
      client.getContractorsDynamics(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });

    const productionCollapse = await new Promise((resolve, reject) => {
      client.getProductionCollapse(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });

    // setSubLocationId(
    //   // structuresExecutionTree.valuesList[0].subitemsList[0].subitemsList[0].id,
    //   0,
    // );
    data.value = structuresExecutionTree.valuesList;
    contractorsData.value = contractorsDynamics.valuesList;
    prodCollapse.value = productionCollapse;
  } catch (error) {
    console.error(error);
  }
  isLoading.value = false;
};

const fetchData = async () => {
  try {
    await fetchDataFromGrpc();
  } catch (err) {
    error.value = "Failed to load data: " + err.message;
    console.error(error.value);
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  await fetchData();
});

// const setSubLocationId = async (index) => {
//   try {
//     await store.dispatch("appData/setCurrentSubLocationId", index);
//   } catch (error) {
//     console.error("Error occurred:", error);
//   }
// };
const textBar = computed(() => {
  return window.innerWidth <= 1540 ? 0.7 : 0.8;
});
</script>

<template>
  <v-container>
    <v-row class="mb-2">
      <v-card class="card-title" width="100%">
        <v-row class="pa-0">
          <v-col cols="4" class="pr-0">
            <v-img
              :src="generateItemImage(2, props.construction.imagesource)"
              width="100%"
              height="9.5em"
              rounded="lg"
              cover
            />
          </v-col>
          <v-col cols="8" class="text-left">
            <v-col class="pa-0 ma-0 pt-3">
              <v-card-text class="pa-0 ma-0 responsive-text"
                >ТЕКУЩИЙ ПРОЕКТ</v-card-text
              >
            </v-col>
            <v-col class="pa-0 ma-0 title-wrapper">
              <v-card-title class="marquee-container pa-0 ma-0">
                <div class="d-flex">
                  <span
                    class="pr-7 marquee responsive-title"
                    :style="{
                      whiteSpace: 'nowrap',
                    }"
                  >
                    {{ props.construction.name }}
                  </span>
                  <span
                    class="pr-7 marquee responsive-title"
                    :style="{
                      whiteSpace: 'nowrap',
                    }"
                  >
                    {{ props.construction.name }}
                  </span>
                </div>
              </v-card-title>
            </v-col>
            <v-col class="pa-0 ma-0 pl-3" align="start">
              <v-row align="center">
                <ProgressBarCardObject
                  title="ВРЕМЯ"
                  :percents="
                    props.construction.constructionplanfact.planpercentage || 0
                  "
                  :size="textBar"
                  :color="1"
                />
              </v-row>
              <v-row align="center">
                <ProgressBarCardObject
                  title="СДАНО"
                  :percents="
                    props.construction.constructionplanfact.factpercentage || 0
                  "
                  :size="textBar"
                  :color="2"
                />
              </v-row>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-row class="mb-2">
      <PropertyCardSubLocation
        :img="imgSignals"
        :data="data"
        :icon="iconQuality"
        title="Участки в отклонении,"
        subtitle="дни"
        :isLoading="isLoading"
      />
    </v-row>
    <v-row>
      <PropertyCard
        :img="imgQuality"
        :data="contractorsData"
        :icon="iconSignals"
        title="Подрядчики в отклонении,"
        subtitle="дни"
        :isLoading="isLoading"
        v-if="clickedButton !== 'ПРОИЗВОДСТВО'"
      />
      <PropertyCard
        :img="imgFiles"
        :data="quality"
        :realData="prodCollapse"
        :icon="iconFiles"
        title="Показатели в отклонении,"
        subtitle="дни"
        :isLoading="isLoading"
        v-else
      />
    </v-row>
  </v-container>
</template>

<style scoped>
.card-title {
  background-color: transparent;
  border: 1px solid #00a3ff;
  padding: 10px;
  box-shadow: inset 0px 0px 15px 5px #00466e;
  border-radius: 8px;
  height: 21.7vh;
}
.responsive-text {
  font-size: 0.8vw;
  color: lightgray;
}
.title-wrapper {
  overflow: hidden;
  padding-bottom: 3vh !important;
}
.responsive-title {
  font-size: 1.5vw;
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}
.marquee {
  display: block; /* Allow horizontal scrolling */
  animation: marquee 15s linear infinite; /* Adjust duration as needed */
}

@keyframes marquee {
  0% {
    transform: translateX(0%); /* Start position */
  }
  100% {
    transform: translateX(-100%); /* End of scroll */
  }
}
@media (min-width: 1920px) {
  .responsive-text {
    font-size: 0.6vw;
  }
  .responsive-title {
    font-size: 1.1vw;
  }
}
@media (max-height: 710px) {
  .card-title {
    height: 18vh;
  }
  .card-title .v-img {
    height: 6.5em !important;
  }
  .responsive-text {
    font-size: 0.6vw;
  }
  .responsive-title {
    font-size: 1.2vw !important;
  }
  .title-wrapper {
    padding-bottom: 2vh !important;
  }
}
@media (min-height: 710px) and (max-height: 760px) {
  .card-title {
    height: 20vh;
  }
  .card-title .v-img {
    height: 7.5em !important;
  }
  .responsive-title {
    font-size: 1.3vw !important;
  }
}
@media (min-height: 760px) and (max-height: 810px) {
  .card-title {
    height: 20.5vh;
  }
  .card-title .v-img {
    height: 9em !important;
  }
}
@media (min-height: 810px) and (max-height: 860px) {
  .card-title {
    height: 21vh;
  }
  .card-title .v-img {
    height: 8em !important;
  }
}
@media (min-height: 810px) and (max-height: 860px) {
  .card-title {
    height: 21vh;
  }
  .card-title .v-img {
    height: 8em !important;
  }
}
</style>
