const calculateMaxValue = (plan, fact, deviation) => {
  return Math.max(
    Math.max(...(plan || [])),
    Math.max(...(fact || [])),
    Math.max(...(deviation || [])),
  );
};

const getChartOptions = (plan, fact, deviation) => {
  const maxValue = calculateMaxValue(plan, fact, deviation);

  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Дата",
          color: "#4285F4",
          align: "start",
        },
        border: {
          color: "#FFFFFF",
        },
        ticks: {
          color: "#fff",
        },
        grid: {
          display: false,
        },
        offset: true,
      },
      y: {
        title: {
          display: true,
          text: "% по ГЛГ",
          color: "#4285F4",
          align: "start",
          padding: {
            bottom: 20,
          },
        },
        grid: {
          drawBorder: true,
          color: (ctx) => {
            return ctx.tick.value === 0 ? "#FFFFFF" : "#666";
          },
          lineWidth: (ctx) => {
            return ctx.tick.value === 0 ? 2 : 1;
          },
        },
        ticks: {
          display: false,
        },
        suggestedMax: maxValue * 1.1,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: (context) => context.dataset.borderColor,
        anchor: "top",
        align: "top",
        clip: true,
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (() => {
          let lastValue = null;
          return function (value) {
            let output;
            if (lastValue === null || lastValue < value) {
              output = `${value.toFixed(2)}% ▲`;
            } else {
              output = `${value.toFixed(2)}% ▼`;
            }
            lastValue = value;
            return output;
          };
        })(),
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "y",
        },
        pan: {
          enabled: true,
          mode: "y",
        },
      },
    },
  };
};

export default {
  getChartOptions,
};
