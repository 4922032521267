<script setup>
import { ref, onMounted, computed } from "vue";
import ProgressBarCardObject from "@/components/data/ProgressBarCardObject.vue";
import TopTable from "@/components/TopTable.vue";
import BarChart from "@/components/Charts/BarChart.vue";
import PieChart from "@/components/Charts/PieChart.vue";
import { useStore } from "vuex";

const {
  ValueRequest_String,
} = require("../../../proto-out/IMultiStatisticsService-v5_pb");

const {
  MultiStatisticsServiceClient,
} = require("../../../proto-out/IMultiStatisticsService-v5_grpc_web_pb");

const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});

const store = useStore();
let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const qualityData = ref({
  contractors: [],
  structures: [],
  technologyCards: [],
});
const executionData = ref({
  contractors: [],
  structures: [],
  technologyCards: [],
});

const clickedButtonTopTen = computed(
  () => store.getters["clickedButton/getClickedButtonTopTen"],
);
const data = ref({});

const isLoading = ref(true);
const currentIndex = ref(0);
const deviationTypes = ["ПО ВЫПОЛНЕНИЮ", "ПО КАЧЕСТВУ"];
const deviationTypesData = {
  "ПО ВЫПОЛНЕНИЮ": executionData.value,
  "ПО КАЧЕСТВУ": qualityData.value,
};

const generateItemImage = (multiply, itemSrc) => {
  if (itemSrc.startsWith("http")) {
    const base = 180;
    return itemSrc + `/S-${base * multiply}`;
  } else {
    return itemSrc;
  }
};

const showNext = () => {
  currentIndex.value = (currentIndex.value + 1) % deviationTypes.length;
  data.value =
    deviationTypesData[deviationTypes[currentIndex.value]][
      clickedButtonTopTen.value
    ];
};
const showPrevious = () => {
  currentIndex.value =
    (currentIndex.value - 1 + deviationTypes.length) % deviationTypes.length;
  data.value =
    deviationTypesData[deviationTypes[currentIndex.value]][
      clickedButtonTopTen.value
    ];
};

const handleButtonClick = (buttonName) => {
  store.dispatch("clickedButton/updateClickedButtonTopTen", buttonName);
  data.value =
    deviationTypesData[deviationTypes[currentIndex.value]][
      clickedButtonTopTen.value
    ];
};

const btnStyle = (buttonName) => {
  return clickedButtonTopTen.value === buttonName
    ? "img-btn-clicked"
    : "img-btn";
};

const fetchDataFromGrpc = async () => {
  try {
    const client = new MultiStatisticsServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };
    const request = new ValueRequest_String();
    request.setValue(props.construction.actualinstance);

    const getTopDeviations = await new Promise((resolve, reject) => {
      client.getTopDeviations(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });

    qualityData.value.contractors =
      getTopDeviations.topdeviationscontractorsqualityList;
    qualityData.value.structures =
      getTopDeviations.topdeviationsstructuresqualityList;
    qualityData.value.technologyCards =
      getTopDeviations.topdeviationstechnologycardsqualityList;

    executionData.value.contractors =
      getTopDeviations.topdeviationscontractorsexecutionList;
    executionData.value.structures =
      getTopDeviations.topdeviationsstructuresexecutionList;
    executionData.value.technologyCards =
      getTopDeviations.topdeviationstechnologycardsexecutionList;

    data.value = getTopDeviations.topdeviationstechnologycardsexecutionList;
    isLoading.value = false;
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};
onMounted(() => {
  fetchDataFromGrpc();
  store.dispatch("clickedButton/updateClickedButtonTopTen", "technologyCards");
});
</script>

<template>
  <v-progress-linear
    color="blue"
    indeterminate
    v-if="isLoading"
  ></v-progress-linear>
  <v-row v-else no-gutters class="d-flex">
    <v-col class="col-2 shrink-0">
      <v-card class="card-title" width="100%">
        <v-row class="pa-0">
          <v-col cols="3" class="img-card pr-0">
            <v-img
              :src="generateItemImage(2, props.construction.imagesource)"
              width="83"
              height="68"
              rounded="lg"
              cover
            />
          </v-col>
          <v-col cols="9" class="text-left text-card">
            <v-col class="pa-0 ma-0 pt-3">
              <v-card-text class="pa-0 ma-0 responsive-text"
                >ТЕКУЩИЙ ПРОЕКТ</v-card-text
              >
            </v-col>
            <v-col class="pa-0 ma-0 title-wrapper">
              <v-card-title class="marquee-container pa-0 ma-0">
                <div class="d-flex">
                  <span
                    class="pr-7 marquee responsive-title"
                    :style="{
                      whiteSpace: 'nowrap',
                    }"
                  >
                    {{ props.construction.name }}
                  </span>
                  <span
                    class="pr-7 marquee responsive-title"
                    :style="{
                      whiteSpace: 'nowrap',
                    }"
                  >
                    {{ props.construction.name }}
                  </span>
                </div>
              </v-card-title>
            </v-col>
          </v-col>
        </v-row>
        <v-row align="center" class="pl-5">
          <ProgressBarCardObject
            title="ВРЕМЯ"
            :percents="
              props.construction.constructionplanfact.planpercentage || 0
            "
            :size="textBar"
            :color="1"
          />
        </v-row>
        <v-row align="center" class="pl-5">
          <ProgressBarCardObject
            title="СДАНО"
            :percents="
              props.construction.constructionplanfact.factpercentage || 0
            "
            :size="textBar"
            :color="2"
          />
        </v-row>
      </v-card>

      <v-card class="side-card">
        <v-row
          class="wrapper align-center justify-center"
          style="gap: 20px; margin-bottom: 20px"
        >
          <img
            src="@/assets/icons/left_line.svg"
            @click="showPrevious"
            style="cursor: pointer"
          />
          <div style="width: 200px">
            <v-card-text class="pa-0 ma-0 responsive-text" align="center"
              >ТОП ОТКЛОНЕНИЙ</v-card-text
            >
            <v-card-text
              class="pa-0 ma-0"
              style="font-size: 18px"
              align="center"
              >{{ deviationTypes[currentIndex] }}</v-card-text
            >
          </div>
          <img
            src="@/assets/icons/right_line.svg"
            @click="showNext"
            style="cursor: pointer"
          />
        </v-row>
        <v-col align="center">
          <v-btn
            @click="handleButtonClick('technologyCards')"
            :class="btnStyle('technologyCards')"
            class="pa-0 pl-3 d-flex align-center justify-start no-padding"
          >
            <v-img
              src="@/assets/icons/document.svg"
              width="44"
              height="44"
              class="me-2"
            ></v-img>
            <span class="text-btn">ПО ТЕХ. КАРТАМ</span>
          </v-btn>
          <v-btn
            @click="handleButtonClick('structures')"
            :class="btnStyle('structures')"
            class="pa-0 pl-3 d-flex align-center justify-start no-padding"
          >
            <v-img
              src="@/assets/icons/building-document.svg"
              width="41"
              height="41"
              class="me-2"
            ></v-img>
            <span class="text-btn">ПО ОБЪЕКТАМ</span>
          </v-btn>
          <v-btn
            @click="handleButtonClick('contractors')"
            :class="btnStyle('contractors')"
            class="pa-0 pl-3 d-flex align-center justify-start no-padding"
          >
            <v-img
              src="@/assets/icons/user-document.svg"
              width="40"
              height="40"
              class="me-2"
            ></v-img>
            <span class="text-btn">ПО ПОДРЯДЧИКАМ</span>
          </v-btn>
        </v-col>
      </v-card>
    </v-col>
    <v-col class="flex-grow-1" style="min-width: 0">
      <div>
        <div class="chart-wrapper pb-3" style="height: 450px; width: 100%">
          <v-img src="@/assets/exports/top-chart-background.svg" cover>
            <v-row class="pa-10 chart-row" style="height: 100%">
              <v-col cols="6">
                <BarChart :data="data" :isLoading="isLoading" />
              </v-col>
              <v-col cols="6">
                <PieChart :data="data" :isLoading="isLoading" />
              </v-col>
            </v-row>
          </v-img>
        </div>
        <div>
          <TopTable :data="data" :isLoading="isLoading" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
.side-card {
  padding-top: 50px;
  background-color: #002b43;
  height: 55vh;
  border-radius: 10px !important;
}
.v-btn {
  margin-bottom: 22px;
  width: 266px;
  height: 63px;
  border: 1px solid #00a3ff;
  border-radius: 10px !important;
}
.text-btn {
  font-size: 18px;
}
.img-btn {
  background: radial-gradient(#00659c 20%, #002336 70%);
}
.img-btn-clicked {
  background: radial-gradient(#008edb 20%, #004a72 70%);
}

.card-title {
  background-color: transparent;
  border: 1px solid #00a3ff;
  padding: 10px;
  box-shadow: inset 0px 0px 15px 5px #00466e;
  height: 18vh;
  border-radius: 10px !important;
  margin-bottom: 23px;
}
.responsive-text {
  font-size: 11px;
  color: lightgray;
}
.title-wrapper {
  overflow: hidden;
  padding-bottom: 1vh !important;
}
.responsive-title {
  font-size: 20px;
}
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}
.marquee {
  display: block; /* Allow horizontal scrolling */
  animation: marquee 15s linear infinite; /* Adjust duration as needed */
}
@keyframes marquee {
  0% {
    transform: translateX(0%); /* Start position */
  }
  100% {
    transform: translateX(-100%); /* End of scroll */
  }
}
.col-2 {
  flex-basis: 350px;
  max-width: 350px;
}
#bar-chart-id {
  height: 100% !important;
}
@media (max-height: 710px) {
  .card-title {
    padding: 10px;
    height: 20vh;
    margin-bottom: 10px;
  }
  .img-card {
    padding-bottom: 0px;
  }
  .text-card {
    padding-bottom: 0px;
  }
  .side-card {
    height: 50vh;
  }
  .chart-wrapper {
    height: 330px !important;
    padding-bottom: 5px !important;
  }
  .chart-row {
    padding-top: 10px !important;
  }
  .v-btn {
    margin-bottom: 15px;
  }
}

@media (min-height: 710px) and (max-height: 760px) {
  .card-title {
    padding: 10px;
    height: 20vh;
    margin-bottom: 10px;
  }
  .img-card {
    padding-bottom: 0px;
  }
  .text-card {
    padding-bottom: 0px;
  }
  .side-card {
    height: 50vh;
  }
  .chart-wrapper {
    height: 330px !important;
    padding-bottom: 5px !important;
  }
  .chart-row {
    padding-top: 10px !important;
  }
}
@media (min-height: 760px) and (max-height: 810px) {
  .card-title {
    padding: 10px;
    height: 20vh;
    margin-bottom: 10px;
  }
  .side-card {
    height: 51vh;
  }
  .chart-wrapper {
    height: 350px !important;
    padding-bottom: 5px !important;
  }
}
@media (min-height: 810px) and (max-height: 860px) {
  .card-title {
    height: 19vh;
    margin-bottom: 10px;
  }
  .side-card {
    height: 54vh;
  }
  .chart-wrapper {
    height: 350px !important;
    padding-bottom: 5px !important;
  }
}
@media (min-height: 860px) and (max-height: 900px) {
  .chart-wrapper {
    height: 400px !important;
    padding-bottom: 5px !important;
  }
}
@media (min-height: 900px) and (min-width: 2000px) {
  .chart-wrapper {
    height: 550px !important;
    padding-bottom: 5px !important;
  }
}
</style>
