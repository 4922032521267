const state = {
  constructionData: [],
  selectedConstructor: null,
};

const getters = {
  constructions: (state) => state.constructionData,
  selectedConstructor: (state) => state.selectedConstructor,
};

const actions = {
  async updateData({ commit }, id, data) {
    await commit("updateConstruction", id, data);
  },
  async setData({ commit }, objects) {
    await commit("setConstructionData", objects);
  },
  async setSelectedConstructor({ commit }, constructor) {
    await commit("setSelectedConstructor", constructor);
  },
};

const mutations = {
  updateConstruction(state, id, data) {
    state.constructionData[id] = data;
  },
  setConstructionData(state, objects) {
    state.constructionData = objects;
  },
  setSelectedConstructor(state, constructor) {
    state.selectedConstructor = constructor;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
