<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

// const getRowClass = (index) => {
//   return index % 2 === 0 ? "transperant-row" : "black-row";
// };
const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});

const getArrowImage = (direction) => {
  return direction
    ? new URL("@/assets/icons/red_arrow.svg", import.meta.url).href
    : new URL("@/assets/icons/green_arrow.svg", import.meta.url).href;
};
const textColor = (direction) => {
  return direction ? "red-status" : "green-status";
};
const tableHeaders = [
  {
    title: "ИСПОЛНИТЕЛИ",
    key: "contractorname",
    sortable: true,
    align: "center",
  },
];

const contractorDevation = ref([]);
const isLoading = ref(true);

const {
  ValueRequest_String,
} = require("../../../proto-out/IMultiStatisticsService-v5_pb");

const {
  MultiStatisticsServiceClient,
} = require("../../../proto-out/IMultiStatisticsService-v5_grpc_web_pb");

const store = useStore();
let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

let predictionDate = computed(
  () => store.getters["appData/getProductionPredictionDate"],
);
let lastLabelDate = computed(
  () => store.getters["appData/getProductionLastLabelDate"],
);

const fetchDataFromGrpc = async () => {
  try {
    const client = new MultiStatisticsServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };

    const request = new ValueRequest_String();
    request.setValue(props.construction.actualinstance);

    const getContractorsDynamicsData = await new Promise((resolve, reject) => {
      client.getContractorsDynamics(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });
    // console.log(getContractorsDynamicsData);
    contractorDevation.value = getContractorsDynamicsData.valuesList;
  } catch (error) {
    console.error(error);
    // throw error;
  }
  isLoading.value = false;
};
onMounted(() => {
  fetchDataFromGrpc();
});
</script>

<template>
  <v-container fluid class="pa-0 pb-5">
    <v-row align="center" class="mx-auto" style="width: 50%">
      <v-col v-if="isLoading" cols="7">
        <v-card-text align="center" class="date">К ДАТЕ: </v-card-text>
      </v-col>
      <v-col v-else cols="7">
        <v-card-text align="center" class="date"
          >К ДАТЕ: {{ lastLabelDate }}</v-card-text
        >
      </v-col>
      <v-col v-if="!isLoading" cols="5">
        <v-card class="red-card" width="199">
          <span class="red-card-text">{{ predictionDate }} дн.</span>
          <span class="legend-text ml-3">ПРОГНОЗ</span>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-card style="background-color: transparent">
    <v-data-table
      :headers="tableHeaders"
      :items="contractorDevation"
      item-value="id"
      height="600"
      fixed-header
      hide-default-footer
      :items-per-page="25"
      density="compact"
      :loading="isLoading"
      loading-text="Загрузка данных..."
    >
      <template v-slot:no-data>
        <div class="text-center">
          <p>Не найдено ни одного элемента</p>
        </div>
      </template>
      <template v-slot:[`item.contractorname`]="{ item }">
        <div
          class="d-flex align-center justify-space-between"
          style="width: 100%; padding-right: 5em; padding-left: 1em"
        >
          <div class="d-flex align-center">
            <v-img
              src="@/assets/icons/hat.svg"
              class="tree-img"
              width="46"
              height="37"
            />
            <span>
              {{ item.contractorname }}
            </span>
          </div>

          <div class="d-flex align-center">
            <span
              class="mr-3"
              :class="textColor(item.currentdynamics?.direction)"
              >{{ item.currentdynamics?.value }} дн.</span
            >
            <img
              :src="getArrowImage(item.currentdynamics?.direction)"
              height="15"
            />
          </div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<style scoped>
.date {
  font-size: 1.5vw;
  color: #00a3ff;
  font-weight: bold;
  padding: 0;
}
.legend-text {
  font-size: 0.7vw;
}
.red-card {
  background-color: red;
}
.red-card-text {
  font-size: 1.6vw;
}
.tree-img {
  margin-right: 10px;
  border-radius: 5px;
}
.green-status {
  font-size: 1vw;
  margin-right: 1em;
  color: #1bc200;
}
.red-status {
  font-size: 1vw;
  margin-right: 1em;
  color: #ff0000;
}
.blue-status {
  font-size: 1vw;
  margin-right: 1em;
  color: #00a3ff;
}
.transperant-row {
  background-color: transparent;
}
.black-row {
  background-color: #00121c;
  padding: 5px 0;
  border-radius: 10px;
}
:deep(.v-data-table thead th) {
  background: linear-gradient(#006aa6, #002940) !important;
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
  text-transform: uppercase;
  font-size: 1.2vw !important;
  min-width: 150px;
}
:deep(.v-data-table thead th:first-child) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
:deep(.v-data-table thead th:last-child) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
:deep(.v-data-table tbody tr) {
  background-color: #00121c;
  font-size: 22px;
}
:deep(tbody .v-data-table__td) {
  padding: 8px 2px !important;
}
:deep(thead .v-data-table__th) {
  padding: 8px 2px !important;
}
.v-data-table {
  background-color: transparent;
}
</style>
