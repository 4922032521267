<script setup>
import ProgressBar from "./data/ProgressBarCamera.vue";
import { useStore } from "vuex";
import { computed } from "vue";

const props = defineProps(["item", "isLoading", "titles"]);
const store = useStore();

const clickedButton = computed(
  () => store.getters["clickedButton/getClickedButton"],
);
const isFutureDate = computed(() => {
  const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
  return (
    props.item.constructionplanfact.startdate.seconds >
    currentTimestampInSeconds
  );
});

const titleColor = () => {
  if (props.item.isfactadded) {
    if (isFutureDate.value) {
      return "red-header";
    } else {
      return "green-header";
    }
  } else {
    return "gray-header";
  }
};
</script>

<template>
  <v-container class="main-card pa-0">
    <v-card class="container mt-2" v-if="!props.isLoading">
      <v-card-text
        class="title pa-0 mx-1"
        :class="titleColor()"
        align="center"
        style="overflow: hidden"
      >
        <div class="d-flex">
          <span class="pr-7 marquee" style="white-space: nowrap">
            {{ props.item.name }}
          </span>
          <span class="pr-7 marquee" style="white-space: nowrap">
            {{ props.item.name }}
          </span>
        </div>
      </v-card-text>
      <v-card class="custom-card mt-3">
        <v-row align="center" class="ma-0 mt-2">
          <ProgressBar
            :title="props.titles[0]"
            :percents="
              (clickedButton === 'КАЧЕСТВО'
                ? props.item.qualityplanfact.planpercentage
                : props.item.constructionplanfact.planpercentage) || 0
            "
            :size="0.55"
            :color="1"
          />
        </v-row>
        <v-row align="center" class="ma-0 mt-2">
          <ProgressBar
            :title="props.titles[1]"
            :percents="
              (clickedButton === 'КАЧЕСТВО'
                ? props.item.qualityplanfact.factpercentage
                : props.item.constructionplanfact.factpercentage) || 0
            "
            :size="0.55"
            :color="2"
          />
        </v-row>
        <v-row align="center" class="ma-0 mt-2 mb-4">
          <ProgressBar
            :title="props.titles[2]"
            :percents="
              (clickedButton === 'КАЧЕСТВО'
                ? props.item.qualityplanfact.deviationpercentage
                : props.item.constructionplanfact.deviationpercentage) || 0
            "
            :size="0.55"
            :color="3"
          />
        </v-row>
      </v-card>
    </v-card>
  </v-container>
</template>

<style scoped>
.main-card {
  position: absolute;
  background: radial-gradient(circle, #106595 20%, #001825 90%);
  top: 2em;
  right: 2em;
  z-index: 1;
  border-radius: 10px;
  width: 16vw;
}
.container {
  background-color: transparent;
}
.title {
  font-size: 1.3vw;
  overflow: hidden;
  display: block;
}
.custom-card {
  text-align: center;
  background-color: transparent;
}
.green-header {
  background: radial-gradient(closest-side, #1bc200, transparent);
}
.red-header {
  background: radial-gradient(closest-side, #ff0000, transparent);
}
.gray-header {
  background: radial-gradient(closest-side, #757575, transparent);
}

.marquee {
  display: inline-block; /* Allow horizontal scrolling */
  animation: marquee 15s linear infinite; /* Adjust duration as needed */
}
:deep(.v-card--variant-elevated) {
  box-shadow: none;
}
@keyframes marquee {
  0% {
    transform: translateX(0%); /* Start position */
  }
  100% {
    transform: translateX(-100%); /* End of scroll */
  }
}
</style>
