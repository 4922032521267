import { onMounted, onBeforeUnmount, watch, createApp, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export const useMap = (
  mapElementId,
  latitude,
  longitude,
  popupComponent,
  iconPathGreen,
  iconPathRed,
  iconPathGray,
) => {
  let map;
  const router = useRouter();
  const store = useStore();
  let markersLayer;

  function goToDetails(id) {
    router.push({ name: "PropertyDetails", params: { id } });
  }

  const getSelectedConstructor = computed(
    () => store.state.locations.selectedConstructor,
  );

  const constructions = computed(() => {
    const allConstructions = store.state.locations.constructionData || [];
    if (!getSelectedConstructor.value) {
      return allConstructions;
    }

    return allConstructions.filter((construction) =>
      construction.contractorsList.some(
        (contractor) =>
          contractor.name.toLowerCase() ===
          getSelectedConstructor.value.toLowerCase(),
      ),
    );
  });

  function iconPath(location) {
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    if (location.isfactadded)
      return location.constructionplanfact.startdate.seconds <
        currentTimestampInSeconds
        ? iconPathGreen
        : iconPathRed;
    else return iconPathGray;
  }

  function renderMarkers() {
    if (markersLayer) {
      map.removeLayer(markersLayer); // Clear previous markers
    }

    markersLayer = L.layerGroup(); // Create a new layer group for markers

    constructions.value.forEach((location) => {
      const popupContainer = document.createElement("div");
      const app = createApp(popupComponent, { data: location });
      app.mount(popupContainer);

      const iconSize = calculateIconSize();

      const icon = L.icon({
        iconUrl: iconPath(location),
        iconSize: [iconSize.width, iconSize.height],
        iconAnchor: [50, 20],
        popupAnchor: [0, -10],
      });

      const marker = L.marker([location.latitude, location.longitude], {
        icon,
      });

      marker.on("mouseover", () => {
        marker.bindPopup(popupContainer).openPopup();
      });

      marker.on("mouseout", () => {
        marker.closePopup();
      });

      marker.on("click", () => {
        goToDetails(location.id);
      });

      markersLayer.addLayer(marker);
    });

    markersLayer.addTo(map);
  }

  function calculateIconSize() {
    const baseWidth = 101; // Base icon width
    const baseHeight = 82; // Base icon height
    const scaleFactor = Math.max(window.innerWidth / 1920, 0.5); // Scale factor based on window width
    return { width: baseWidth * scaleFactor, height: baseHeight * scaleFactor };
  }

  onMounted(() => {
    try {
      map = L.map(mapElementId).setView([latitude, longitude], 13);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attributionControl: false,
      }).addTo(map);

      renderMarkers(); // Initial rendering of markers

      // Watch for changes in constructions and re-render markers
      watch(constructions, renderMarkers, { deep: true });
    } catch (error) {
      console.error("Error initializing map:", error);
    }
  });

  onBeforeUnmount(() => {
    if (map) {
      map.remove(); // Cleanup map instance on component unmount
    }
  });

  const invalidateSize = () => {
    if (map) {
      map.invalidateSize();
    }
  };

  return { map, invalidateSize };
};
