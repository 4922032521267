const quality = [
  {
    id: 1,
    contractorname: "МАТ 1 В ОТКЛ.",
    currentdynamics: {
      value: "+3",
      direction: false,
    },
  },
  {
    id: 2,
    contractorname: "ТАРА 1 В ОТКЛ.",
    currentdynamics: {
      value: "-2",
      direction: false,
    },
  },
  {
    id: 3,
    contractorname: "ТТК В ОТКЛ.",
    currentdynamics: {
      value: "-3",
      direction: false,
    },
  },
  {
    id: 4,
    contractorname: "ПРОДУКТ 1 В ОТКЛ.",
    currentdynamics: {
      value: "+4",
      direction: false,
    },
  },
];

const cameras = [
  {
    id: 1,
    name: "КАМЕРА 1",
    img: new URL("@/assets/video/camera_default.png", import.meta.url).href,
  },
  {
    id: 2,
    name: "КАМЕРА 2",
    img: new URL("@/assets/video/camera_default.png", import.meta.url).href,
  },
  {
    id: 3,
    name: "КАМЕРА 3",
    img: new URL("@/assets/video/camera_default.png", import.meta.url).href,
  },
  {
    id: 4,
    name: "КАМЕРА 4",
    img: new URL("@/assets/video/camera_default.png", import.meta.url).href,
  },
  {
    id: 5,
    name: "КАМЕРА 5",
    img: new URL("@/assets/video/camera_default.png", import.meta.url).href,
  },
  {
    id: 6,
    name: "КАМЕРА 6",
    img: new URL("@/assets/video/camera_default.png", import.meta.url).href,
  },
];

export default {
  quality,
  cameras,
};
