const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      clip: true,
      color: (context) => context.dataset.borderColor,
      anchor: "top",
      align: "top",
      font: {
        weight: "bold",
        size: 12,
      },
      formatter: (() => {
        let lastValue = null;
        return function (value) {
          let output;
          if (lastValue === null || lastValue < value) {
            output = `${value.toFixed(2)}% ▲`;
          } else {
            output = `${value.toFixed(2)}% ▼`;
          }
          lastValue = value;
          return output;
        };
      })(),
    },
    tooltip: {
      enabled: true,
    },
  },
  scales: {
    x: {
      ticks: {
        display: true,
        color: "white",
      },
      border: {
        color: "#FFFFFF",
      },
      grid: {
        display: false,
      },
      offset: true,
    },
    y: {
      ticks: {
        display: false,
      },
    },
  },
};

const icons = [
  {
    src: new URL("@/assets/bg/product.png", import.meta.url).href,
    label: "ПРОДУКТ",
  },
  {
    src: new URL("@/assets/bg/ttk.png", import.meta.url).href,
    label: "ТТК",
  },
  {
    src: new URL("@/assets/bg/tara.png", import.meta.url).href,
    label: "ТАРА",
  },
  {
    src: new URL("@/assets/bg/material.png", import.meta.url).href,
    label: "МАТЕРИАЛ",
  },
];
export default {
  chartOptions,
  icons,
};
