<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  data: Object,
  isLoading: Boolean,
});

const store = useStore();

const clickedButtonTopTen = computed(
  () => store.getters["clickedButton/getClickedButtonTopTen"],
);
const name = computed(() =>
  clickedButtonTopTen.value === "technologyCards"
    ? "Технологическая карта"
    : clickedButtonTopTen.value === "contractors"
      ? "Подрядчик"
      : "Oбъект",
);

const topTenHeaders = [
  { title: "№", key: "num", maxWidth: "50px" },
  {
    title: name,
    key: "name",
    align: "start",
    width: "700px",
  },
  { title: "Кол-во отклонений", key: "count", align: "center" },
  { title: "Вес в структуре", key: "percent", align: "center" },
];
</script>

<template class="custom-table">
  <v-card>
    <v-data-table
      :headers="topTenHeaders"
      :items="props.data"
      class="table"
      height="260px"
      fixed-header
      hide-default-footer
      :loading="props.isLoading"
      :items-per-page="-1"
      loading-text="Загрузка данных..."
    >
      <template v-slot:no-data>
        <div class="text-center">
          <p>Не найдено ни одного элемента</p>
        </div>
      </template>
      <template v-slot:[`item.percent`]="{ item }">
        <span> {{ item.percent?.toFixed(2) }}% </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<style scoped>
:deep(::-webkit-scrollbar-track) {
  margin-top: 50px;
  margin-bottom: 0;
}
.v-data-table {
  background: #09182e;
  font-size: 22px;
}
:deep(.v-data-table thead th) {
  background: #09182e !important;
  color: #00a3ff;
}
:deep(tbody tr:nth-of-type(even)) {
  background: #212f42 !important;
}
:deep(tbody tr:nth-of-type(odd)) {
  background: #09182e !important;
}
:deep(.v-data-table tbody tr:hover) {
  opacity: 0.7;
  transition: opacity 0.3s ease;
}
@media (max-height: 710px) {
  .v-data-table {
    font-size: 18px;
  }
  .table {
    height: 21vh !important;
  }
}
@media (min-height: 710px) and (max-height: 760px) {
  .v-data-table {
    font-size: 19px;
  }
  .table {
    height: 24vh !important;
  }
}
@media (min-height: 760px) and (max-height: 810px) {
  .v-data-table {
    font-size: 19px;
  }
  .table {
    height: 28vh !important;
  }
}
@media (min-height: 810px) and (max-height: 860px) {
  .v-data-table {
    font-size: 19px;
  }
  .table {
    height: 32vh !important;
  }
}
@media (min-height: 860px) and (max-height: 900px) {
  .table {
    height: 29vh !important;
  }
}
@media (min-height: 900px) and (min-width: 2000px) {
  .table {
    height: 18vh !important;
  }
}
</style>
