<template>
  <Bar id="bar-chart-id" :options="chartOptions" :data="chartData" />
</template>

<script setup>
import { onMounted, computed, watch, ref, onBeforeUnmount } from "vue";
import { Bar } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

// Register Chart.js elements
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels,
);

const props = defineProps({
  data: Array, // Ensure this is an array of data
  isLoading: Boolean,
});

// Compute maxY dynamically based on the counts in the data
const maxY = computed(() => {
  const maxValue = Math.max(...props.data.map((item) => item.count));
  return Math.ceil(maxValue / 10) * 10; // Round to the nearest 10
});

// Compute stepSize based on the new maxY
const stepSize = computed(() => Math.ceil(maxY.value / 5));

// Dynamically update chartData when data changes
const chartData = computed(() => ({
  labels: props.data.map((item) => item.num),
  datasets: [
    {
      data: props.data.map((item) => item.count),
      backgroundColor: [
        "rgb(240,50,0)",
        "rgb(83,236,18)",
        "rgb(255,183,0)",
        "rgb(0,168,238)",
        "rgb(207,0,142)",
      ],
      borderColor: "white",
      borderWidth: 1,
    },
  ],
}));

// Chart options that depend on maxY and stepSize
const chartOptions = computed(() => ({
  responsive: true,
  scales: {
    x: {
      ticks: {
        color: "white",
        font: { size: 20 },
      },
      grid: { drawOnChartArea: false },
    },
    y: {
      ticks: {
        color: "white",
        font: { size: 20 },
        stepSize: stepSize.value, // Use reactive stepSize
      },
      max: maxY.value, // Use reactive maxY
      grid: {
        drawOnChartArea: true,
        color: "rgba(255, 255, 255, 0.7)",
        lineWidth: 1,
      },
    },
  },
  plugins: {
    legend: { display: false },
    datalabels: { display: false },
    tooltip: {
      enabled: true,
      callbacks: {
        title: (tooltipItems) => {
          const index = tooltipItems[0].dataIndex;
          const fullTitle = props.data[index].name;
          const maxLineLength = 30;
          const lines =
            fullTitle.match(new RegExp(`.{1,${maxLineLength}}`, "g")) || [];
          return lines;
        },
        label: () => "",
      },
    },
  },
  animations: {
    tension: {
      duration: 300,
      easing: "easeInOutQuad",
    },
  },
}));

// Watch for changes in maxY and stepSize to update the chart
watch([maxY, stepSize], () => {
  const chart = ChartJS.getChart("bar-chart-id");
  if (chart) {
    chart.update(); // Update the chart when maxY or stepSize changes
  }
});
const resizeChart = () => {
  if (chartRef.value?.chart) {
    chartRef.value.chart.resize();
  }
};
const chartRef = ref(null);
const chartContainerRef = ref(null);
onMounted(() => {
  const observer = new ResizeObserver(() => {
    resizeChart();
  });

  if (chartContainerRef.value) {
    observer.observe(chartContainerRef.value);
  }

  window.addEventListener("resize", resizeChart);

  onBeforeUnmount(() => {
    window.removeEventListener("resize", resizeChart);
    if (chartContainerRef.value) {
      observer.unobserve(chartContainerRef.value);
    }
  });
  setTimeout(() => {
    const chart = ChartJS.getChart("bar-chart-id");
    if (chart) {
      const gradients = createGradients(chart);
      chart.data.datasets[0].backgroundColor = gradients;
      chart.update();
    }
  }, 720);
});

// Gradient function to create custom color gradients for bars
function createGradients(chart) {
  const ctx = chart.ctx;
  const meta = chart.getDatasetMeta(0);
  const chartArea = chart.chartArea;

  if (!chartArea) return;

  return meta.data.map((bar, index) => {
    const barX = bar.x + bar.width / 2;
    const barY = bar.y;
    const barHeight = chartArea.bottom - barY;

    const gradient = ctx.createRadialGradient(
      barX,
      barY,
      0,
      barX,
      barY,
      barHeight,
    );
    gradient.addColorStop(0, gradientColors[index % gradientColors.length][0]);
    gradient.addColorStop(1, gradientColors[index % gradientColors.length][1]);
    return gradient;
  });
}

const gradientColors = [
  ["rgba(240,50,0,1)", "rgba(193,11,0,1)"], // Red
  ["rgba(83,236,18,1)", "rgba(25,212,34,1)"], // Green
  ["rgba(255,183,0,1)", "rgba(254,93,0,1)"], // Orange
  ["rgba(0,168,238,1)", "rgba(0,73,214,1)"], // Blue
  ["rgba(207,0,142,1)", "rgba(154,0,253,1)"], // Purple
];
</script>
